<template>
  <div id="AmazonPayButton"></div>
</template>

<script setup lang="ts">
import { onMounted } from "vue";
import { omit } from "lodash";
import { useCartStore } from "@/stores/cart";

import { NORMAL_MODE, UPSELL_MODE } from "@/helpers/constants";

interface Props {
  payloads: any;
}

const props = defineProps<Props>();
const cartStore = useCartStore();

const checkoutPayloadConfig = props.payloads.createCheckoutSessionConfig;

onMounted(() => {
  const amazonPayButton = amazon.Pay.renderButton("#AmazonPayButton", {
    ledgerCurrency: "JPY",
    sandbox: true,

    checkoutLanguage: "ja_JP",
    buttonColor: "Gold",
    ...omit(props.payloads, "createCheckoutSessionConfig"),
  });

  if (props.payloads.signInConfig) return;

  amazonPayButton.onClick(function () {
    if ([NORMAL_MODE, UPSELL_MODE].includes(cartStore?.orderInfo?.orderMode as string)) {
      cartStore
        .ordersPaymentAmountValidate(
          checkoutPayloadConfig.payloadJSON.paymentDetails.chargeAmount.amount,
          "amazon_pay",
          cartStore.orderInfo?.isRecurring,
        )
        .then(() => {
          amazonPayButton.initCheckout({
            createCheckoutSessionConfig: checkoutPayloadConfig,
          });
        })
        .catch(() => {
          cartStore.handleErrorWhenSubmit();
        });
    } else {
      amazonPayButton.initCheckout({
        createCheckoutSessionConfig: checkoutPayloadConfig,
      });
    }
  });
});
</script>
