export const selectOptions = `
  query ($keys: [String!]!) {
    selectOptions(keys: $keys) {
      sexes {
        text
        value
      }
      prefectures {
        text
        value
      }
      dayOfWeekJpOptions {
        text
        value
      }
      subsOrderReasonInMyPage {
        text
        value
      }
      contactTypeOptions {
        text
        value
      }
    }
  }
`;

export const fetchShippingCarrierOptions = `
query ($input: OrderFetchShippingCarrierInput!) {
  ordersFetchShippingCarrierOptions(input: $input) {
    id
    name
    default
  }
}
`;

export const fetchShippingCarrierDefault = `
  query {
    ordersFetchShippingCarrierDefault{
        id
        name
    }
  }
`;

export const fetchShippingCarrierPaymentMethod = `
query ($paymentMethodCode: String!) {
    ordersFetchShippingCarrierPaymentMethod(paymentMethodCode: $paymentMethodCode) {
        id
        name
    }
  }
`;

export const ordersPaymentAmazonPayGetBuyerInfo = `
query ($buyerToken: String) {
  orderPaymentAmazonPayGetBuyerInfo(buyerToken: $buyerToken)
}
`;

export const orderPaymentAmazonPayGetCreateCheckoutSessionSignature = `
query ($addressDetails: AddressDetailsInput!, $amount: String!, $isRecurring: Boolean!) {
  orderPaymentAmazonPayGetCreateCheckoutSessionSignature(addressDetails: $addressDetails, amount: $amount, isRecurring: $isRecurring)
}
`;

export const orderPaymentPaygentThreeDSecure = `
query ($input: PublicsPaygentThreeDSecureInput) {
  orderPaymentPaygentThreeDSecure(input: $input) {
    redirectUrl
    token
    customerCardId
  }
}
`;

export const ordersPaymentAmountValidate = `
mutation($amount: String!, $paymentMethodCode: String!, $isRecurring:  Boolean! ) {
  ordersPaymentAmountValidate(amount: $amount, paymentMethodCode: $paymentMethodCode, isRecurring: $isRecurring) {
    message
  }
}
`;
