<template>
  <div class="process-container mt-10 mb-20" v-show="products.length">
    <div class="row justify-content-evenly gy-10">
      <div
        class="col-sm-12 col-md-6 text-center d-flex flex-column justify-content-between mb-5"
        v-for="(product, index) in products"
        :key="index"
      >
        <div>
          <div class="img">
            <img class="w-100" :src="noImageUrl" alt="no image" />
          </div>
          <div class="mt-3">{{ product.name }}</div>
          <div class="mt-3">
            販売価格:
            <span>{{ getFinalPriceProduct(product, index) }}円</span>
            <span>（税込）</span>
          </div>

          <cv-xsell-variant-input
            :data="getData(product.id)"
            :product-index="index"
            :variant-index="0"
            :component-index="0"
          ></cv-xsell-variant-input>
        </div>

        <div>
          <b-button
            variant="primary"
            class="w-100 mt-4"
            :disabled="checkDisableAddButton(product.id)"
            @click="selectProduct(product.id)"
          >
            追加する
          </b-button>
        </div>
      </div>
    </div>

    <template v-if="orderStore.xSellVariantInput.length">
      <div class="px-5 mt-10 table-responsive">
        <p class="mt-10">購入した商品に加えて、以下の商品を追加で購入します。</p>

        <table class="table table-flush align-middle table-row-solid gy-1 gx-2 gs-9 mb-0">
          <thead class="fs-7 fw-bold text-uppercase">
            <tr>
              <th>商品名</th>
              <th>単価</th>
              <th>個数</th>
              <th>小計</th>
              <th width="10%"></th>
            </tr>
          </thead>
          <tbody class="fs-6 text-gray-600">
            <tr v-for="(variant, index) in orderStore.xSellVariantInput" :key="index">
              <td style="white-space: pre-line">{{ variant.name }}</td>
              <td>{{ variant.price }} 円</td>
              <td>
                <div class="d-flex align-items-center">
                  <form-validator
                    :name="`product_data['products_data.${index}.quantity']`"
                    required
                    float
                  >
                    <b-select
                      style="width: 75px"
                      v-model="orderStore.xSellVariantInput[index].quantity"
                      class="form-control"
                      :options="getVariantQuantityOptions(variant)"
                      label-field="quantity"
                    >
                    </b-select>
                  </form-validator>
                  <label class="p-1">個</label>
                </div>
              </td>
              <td>{{ getTotalPrice(variant) }} 円</td>
              <td class="p-0">
                <b-button variant="danger" class="m-2 p-1" @click="deleteProductSelected(variant)">
                  削除
                </b-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
    <p class="mt-10">※ 各販売価格及び単価、小計は税込金額です。</p>

    <specify-delivery-date no-padding :order-mode="XSELL_MODE" />

    <div class="text-center my-10" v-if="orderStore.xSellVariantInput.length">
      <button class="process-confirm-btn" @click="onSubmit">申し込み内容を確認する</button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, onBeforeMount, watch } from "vue";

import { useCartStore } from "@/stores/cart";
import { useOrderStore } from "@/stores/order";
import { useValidationStore } from "@/stores/form/validation";
import { useBaseInfoStore } from "@/stores/base_info";

import { useForm, useField } from "vee-validate";
import * as yup from "yup";

import { some } from "lodash";

import { VariantSelectedInput } from "@/types/input/OfferInput";

import CvXsellVariantInput from "@/components/organisms/pages/cart/CvXsellVariantInput.vue";
import SpecifyDeliveryDate from "@/components/organisms/pages/cart/SpecifyDeliveryDate.vue";

import { NORMAL_MODE, CV_UPSELL_MODE, XSELL_MODE, UPSELL_MODE } from "@/helpers/constants";

interface Props {
  orderNumber: string;
  baseUrl: string;
}

const props = defineProps<Props>();

const cartStore = useCartStore();
const orderStore = useOrderStore();
const validationStore = useValidationStore();
const baseInfoStore = useBaseInfoStore();

const noImageUrl = ref("/images/no-image.png");

const { validate } = useForm({
  validationSchema: yup.object().shape({
    subsOrderData: yup.object({
      paymentSchedule: yup.string().nullable(),
      scheduledToBeDeliveredEveryXMonth: yup
        .number()
        .nullable()
        .when(["paymentSchedule"], {
          is: (paymentSchedule: any) => ["date", "day_of_week"].includes(paymentSchedule),
          then: (schema) => schema.required(),
        }),
      scheduledToBeDeliveredOnXthDay: yup
        .number()
        .nullable()
        .when(["paymentSchedule"], {
          is: (paymentSchedule: any) => paymentSchedule == "date",
          then: (schema) => schema.required(),
        }),
      scheduledToBeDeliveredEveryXDay: yup
        .number()
        .nullable()
        .when(["paymentSchedule"], {
          is: (paymentSchedule: any) => paymentSchedule == "term",
          then: (schema) => schema.required(),
        }),
      scheduledToBeDeliveredEveryXDayOfWeek: yup
        .number()
        .nullable()
        .when(["paymentSchedule"], {
          is: (paymentSchedule: any) => paymentSchedule == "day_of_week",
          then: (schema) => schema.required(),
        }),
      scheduledToBeDeliveredOnXthDayOfWeek: yup
        .number()
        .nullable()
        .when(["paymentSchedule"], {
          is: (paymentSchedule: any) => paymentSchedule == "day_of_week",
          then: (schema) => schema.required(),
        }),
    }),
  }),
  initialValues: {
    subsOrderData: {
      paymentSchedule: "",
      scheduledToBeDeliveredEveryXMonth: null,
      scheduledToBeDeliveredOnXthDay: null,
      scheduledToBeDeliveredEveryXDay: null,
      scheduledToBeDeliveredEveryXDayOfWeek: null,
      scheduledToBeDeliveredOnXthDayOfWeek: null,
    },
  },
});

const { value: subsOrderData }: any = useField("subsOrderData");
const { value: productsData }: any = useField("productsData");
const { value: specifyDeliveryDate }: any = useField("specifyDeliveryDate");

const { value: shippingAddressData }: any = useField("shippingAddressData");
const { value: billingAddressData }: any = useField("billingAddressData");
const { value: shippingCarrierId }: any = useField("shippingCarrierId");

const products = computed(() => orderStore.productInfo.cvXsellProducts);

const mode = computed(() => baseInfoStore.baseInfo?.shippingCarrierBasedOn ?? "");

const shippingCarrierVariables = computed(() => ({
  input: {
    paymentMethodCode: cartStore.cart.paymentData.paymentMethodCode,
    productData: orderStore.xSellVariantInput.map((item) => {
      return {
        id: item.productId,
        quantity: item.quantity,
        variantData: [
          {
            variantId: item.id,
          },
        ],
      };
    }),
    orderMode: XSELL_MODE,
    orderNumber: props.orderNumber,
  },
}));

const shippingCarrierOptionsXsell = computed(() => cartStore.shippingCarrierOptionsXsell);

watch(shippingCarrierOptionsXsell, () => {
  shippingCarrierId.value = shippingCarrierOptionsXsell.value?.[0]?.id;
});

watch(
  () => orderStore.xSellVariantInput,
  () => {
    if (mode.value == "payment_method") return;
    cartStore.fetchShippingCarrierOptions(shippingCarrierVariables.value, XSELL_MODE);
  },
  { deep: true },
);

const onSubmit = async function () {
  validationStore.submitAttempts++;

  const result = await validate();

  if (result.valid) validateModification();
};

const validateModification = () => {
  const variantData = orderStore.xSellVariantInput.map((item) => {
    return {
      id: item.id,
      quantity: item.quantity,
    };
  });

  cartStore
    .validateOfferCvXsell(
      props.orderNumber,
      variantData,
      subsOrderData.value,
      specifyDeliveryDate.value,
      shippingCarrierId.value,
    )
    .then(() => {
      cartStore.offerData[props.orderNumber] = variantData;
      cartStore.offerData.isRecurring = some(orderStore.xSellVariantInput, "isRecurring");

      cartStore.offerData.subsOrderData = subsOrderData.value;
      cartStore.offerData.specifyDeliveryDate = specifyDeliveryDate.value;
      cartStore.offerData.shippingCarrierId = shippingCarrierId.value;

      window.location.href = "/lp/cv_confirm" + window.location.search + "&mode=" + XSELL_MODE;
    });
};

function selectProduct(productId: number) {
  const product = orderStore.productVariantInput.find((item) => item.id == productId);

  if (product) {
    const variantId = product.variantId;
    if (orderStore.xSellVariantInput.map((item) => item.id).includes(variantId)) {
      const variantIndex = orderStore.xSellVariantInput.findIndex((item) => item.id == variantId);
      orderStore.xSellVariantInput[variantIndex].quantity += 1 as any;
    } else {
      const productSelected = orderStore.productInfo.cvXsellProducts.find(
        (item) => item.id == product.id,
      );

      if (productSelected) {
        product.count += 1 as any;

        const variantSelected = productSelected.variants.find((item) => item.id == variantId);

        if (variantSelected) {
          orderStore.xSellVariantInput.push({
            id: variantId,
            name: variantSelected.variantName.downLine,
            quantity: variantSelected.minQuantity,
            minQuantity: variantSelected.minQuantity,
            maxQuantity: variantSelected.maxQuantity,
            price: variantSelected.salesPrice,
            productId: productId,
            isRecurring: product.isRecurring,
          });

          productsData.value = orderStore.xSellVariantInput.map((item) => {
            return {
              id: item.productId,
              isRecurring: item.isRecurring,
            };
          });
        }
      }
    }
  }
}

function getTotalPrice(variant: VariantSelectedInput) {
  if (variant.quantity && variant.price) {
    return variant.quantity * variant.price;
  }
  return 0;
}

function deleteProductSelected(variant: VariantSelectedInput) {
  const product = orderStore.productVariantInput.find((item) => item.id == variant.productId);

  if (product && product.count) product.count -= 1 as any;

  orderStore.xSellVariantInput = orderStore.xSellVariantInput.filter(
    (item) => item.id !== variant.id,
  );

  productsData.value = orderStore.xSellVariantInput.map((item) => {
    return {
      id: item.productId,
      isRecurring: item.isRecurring,
    };
  });
}

const range = (start: number, stop: number, step: number) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

const getData = (productId: number | null) => {
  const product = products.value.find((item) => item.id == productId);

  if (product) {
    return product.variantCombinations;
  }

  return {};
};

const getVariantQuantityOptions = (variant: VariantSelectedInput) => {
  if (variant.minQuantity && variant.maxQuantity) {
    return range(variant.minQuantity, variant.maxQuantity, 1);
  }

  return range(1, 10, 1);
};

const checkDisableAddButton = (productId: number) => {
  const currentVariantSelected = orderStore.productVariantInput.find(
    (item) => item.id == productId,
  );

  if (currentVariantSelected && currentVariantSelected.variantId) {
    const variant = orderStore.xSellVariantInput.find(
      (item) => item.id == currentVariantSelected.variantId,
    );

    if (variant && variant.quantity && variant.maxQuantity) {
      return variant.quantity >= variant.maxQuantity;
    } else {
      return false;
    }
  } else {
    return true;
  }
};

onBeforeMount(() => {
  shippingAddressData.value = {
    sameWithBillingAddress: cartStore.cart.shippingAddressData.sameWithBillingAddress,
    prefectureId: cartStore.cart.shippingAddressData.prefectureId,
  };

  billingAddressData.value = { prefectureId: cartStore.cart.billingAddressData.prefectureId };

  shippingCarrierId.value = cartStore.cart.shippingCarrierId;
});

function getFinalPriceProduct(product: any, index: number) {
  const variantId = orderStore.productVariantInput[index]?.variantId ?? null;

  if (product.recurringPrice || product.recurringPrice === 0) return product.recurringPrice;
  else if (!variantId) {
    return product.masterSalesPrice;
  } else {
    const variantSelected = product.variants.find((item: any) => item.id == variantId);

    return variantSelected?.salesPrice || product.masterSalesPrice;
  }
}
</script>

<style lang="scss" scoped>
.table-responsive {
  overflow: scroll;
  overflow-y: hidden;
}

.table {
  th,
  td {
    padding: 10px !important;
  }

  min-width: 350px;
}

.btn-danger {
  white-space: nowrap !important;
  width: 100%;
}
</style>
