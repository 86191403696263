<template>
  <process-layout title="注文確認画面" step="confirm">
    <CartSummary :order-info="orderInfo" confirm-mode="confirm"></CartSummary>
    <div class="text-center mt-5">
      <div
        class="d-flex justify-content-center mb-3"
        v-if="
          cart.paymentData.paymentMethodCode == 'amazon_pay' && cartStore.checkoutSessionSignature
        "
      >
        <AmazonPayButton :payloads="amazonPayloads" />
      </div>

      <div
        class="text-danger"
        v-else-if="
          cart.paymentData.paymentMethodCode == 'amazon_pay' && !cartStore.checkoutSessionSignature
        "
      >
        <span>Error installing Amazon Pay</span> <br />
        <span>Please change your payment method!</span>
      </div>
      <div class="mb-3" v-else>
        <button
          class="process-confirm-btn"
          @click="submit"
          name="submit_confirm"
          id="submit_confirm"
        >
          ご注文完了へ
        </button>
      </div>

      <div class="mb-2">
        <button class="mx-2 process-back-btn p-0" @click="back">入力内容を修正する</button>
      </div>
    </div>
  </process-layout>
</template>

<script setup lang="ts">
import { computed, onBeforeMount } from "vue";

import { omit } from "lodash";
import { findByCode } from "jp-prefectures";

import ProcessLayout from "@/components/organisms/pages/lp/ProcessLayout.vue";
import CartSummary from "@/components/organisms/pages/cart/CartSummary.vue";
import AmazonPayButton from "@/components/molecules/pages/cart/payment/amazon_pay/AmazonPayButton.vue";

import { useCartStore } from "@/stores/cart";
import { useAuthStore } from "@/stores/auth";
import { useOrderStore } from "@/stores/order";

import { PAYGENT_PAYMENT_METHOD_CODE } from "@/helpers/constants";

interface Props {
  finishPath: string;
  baseUrl: string;
  cvUpsellPath?: string;
  cvXsellPath?: string;
  amazonInfo: any;
  paygentInfo: any;
}

const props = defineProps<Props>();
const amazonInfo = computed(() => {
  if (props.amazonInfo) {
    return JSON.parse(props.amazonInfo);
  } else {
    return null;
  }
});

const paygentEnableThreeDSecure = computed(() => {
  if (props.paygentInfo) {
    const paygentInfo = JSON.parse(props.paygentInfo);
    return paygentInfo.enable_three_d_secure;
  } else {
    return false;
  }
});

const cartStore = useCartStore();

const authStore = useAuthStore();
const orderStore = useOrderStore();

const { orderInfo, cart } = cartStore;

const upsellNormal = cart.urlData.upsellNormal;

const addressDetails = {
  stateOrRegion: encodeURIComponent(
    findByCode(cartStore.cart.billingAddressData.prefectureId)?.name,
  ),
  addressLine1: encodeURIComponent(cartStore.cart.billingAddressData.addr01),
  addressLine2: encodeURIComponent(cartStore.cart.billingAddressData.addr02),
  addressLine3: null,
  name: encodeURIComponent(
    `${cartStore.cart.billingAddressData.name01}${cartStore.cart.billingAddressData.name02}`,
  ),
  postalCode: `${cartStore.cart.billingAddressData.zip01}${cartStore.cart.billingAddressData.zip02}`,
  phoneNumber: `${cartStore.cart.billingAddressData.tel01}${cartStore.cart.billingAddressData.tel02}${cartStore.cart.billingAddressData.tel03}`,
};

const amazonPayloads = computed(() => {
  const recurringData = {
    chargePermissionType: "Recurring",
    recurringMetadata: {
      frequency: {
        unit: "Variable",
        value: "0",
      },
      amount: {
        amount: String(orderInfo?.total),
        currencyCode: "JPY",
      },
    },
  };

  const payloadJSON = {
    storeId: amazonInfo.value?.store_id,

    webCheckoutDetails: {
      checkoutReviewReturnUrl: null,
      checkoutResultReturnUrl: amazonInfo.value?.checkout_result_return_url,
      checkoutMode: "ProcessOrder",
    },
    paymentDetails: {
      paymentIntent: "Confirm",
      chargeAmount: {
        amount: String(orderInfo?.total),
        currencyCode: "JPY",
      },
      allowOvercharge: !orderInfo?.isRecurring,
    },

    addressDetails: {
      ...addressDetails,
      countryCode: "JP",
    },
  };

  if (orderInfo?.isRecurring) Object.assign(payloadJSON, recurringData);

  return {
    merchantId: amazonInfo.value?.merchant_id,

    productType: "PayAndShip",
    placement: "Checkout",

    createCheckoutSessionConfig: {
      payloadJSON: payloadJSON,
      signature: cartStore.checkoutSessionSignature,
      publicKeyId: amazonInfo.value?.public_key_id,
    },
  };
});

onBeforeMount(async () => {
  if (!orderInfo) {
    window.location.href = "/lp?u=" + props.baseUrl;
    return;
  }
  if (cart.paymentData.paymentMethodCode == "amazon_pay") {
    await cartStore.orderPaymentAmazonPayGetCreateCheckoutSessionSignature(
      addressDetails,
      String(orderInfo?.total),
      orderInfo?.isRecurring,
    );
  }

  cartStore.lastPage = cartStore.cart.urlData.upsellNormal ? "upsell_back_confirm" : "confirm";

  cartStore.fetchUrl(props.baseUrl);
});

function submit() {
  if (
    cart.paymentData.paymentMethodCode == PAYGENT_PAYMENT_METHOD_CODE &&
    paygentEnableThreeDSecure.value
  ) {
    const paymentData = omit(cart.paymentData, ["isAddNewCard", "card"]);
    const input = {
      paymentData: paymentData,
      customerData: cart.customerData,
      amount: String(orderInfo?.total),
    };

    cartStore
      .orderPaymentPaygentThreeDSecure(input)
      .then((data: any) => {
        if (data.token) authStore.setTokenAndCookie(data.token);
        if (data.customerCardId) {
          cart.paymentData.sourceId = data.customerCardId;
        }
        window.location.href = data.redirectUrl;
      })
      .catch(() => {
        cartStore.handleErrorWhenSubmit();
      });

    return;
  }

  cartStore
    .submitCart()
    .then((data: any) => {
      if (data.token) authStore.setTokenAndCookie(data.token);
      if (!upsellNormal && (data.cvUpsellAvailable || data.cvXsellAvailable)) {
        window.location.href =
          props.cvUpsellPath + window.location.search + "&order_id=" + data.orderNumber;
      } else {
        cartStore.$reset();
        window.location.href =
          props.finishPath + window.location.search + "&order_id=" + data.orderNumber;
      }
      orderStore.resetPageView(props.baseUrl);
    })
    .catch(() => {
      cartStore.handleErrorWhenSubmit();
    });
}

function back() {
  if (upsellNormal) cart.urlData.upsellBack = true;

  window.location.href = "/lp?u=" + props.baseUrl;
}
</script>
