<template>
  <div class="process-container" v-show="products.length">
    <b-card>
      <div class="row">
        <table class="table bg-white mb-0">
          <tbody>
            <tr class="align-middle">
              <th width="25%" class="p-3">
                <span>商品名</span>
              </th>
              <td class="p-3">
                <b-form-select
                  v-model="orderStore.cvUpSellVariantData.productSelectedId"
                  :options="products"
                  text-field="name"
                  value-field="id"
                  @change="handleChangeProduct($event)"
                ></b-form-select>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="row justify-content-md-end mb-10">
        <div class="upsell-variant text-center">
          <cv-upsell-variant-input
            key-component="cvUpSellVariantData"
            :data="getData(productId)"
            :product-index="0"
            :variant-index="0"
            :component-index="0"
          ></cv-upsell-variant-input>
        </div>

        <specify-delivery-date no-padding :order-mode="CV_UPSELL_MODE" />
      </div>

      <div class="text-center">
        <button class="process-confirm-btn" @click="onSubmit">申し込み内容を確認する</button>
      </div>
    </b-card>
  </div>
</template>

<script setup lang="ts">
import { computed, watch, onBeforeMount } from "vue";

import { useOrderStore } from "@/stores/order";
import { useCartStore } from "@/stores/cart";
import { useValidationStore } from "@/stores/form/validation";
import { useBaseInfoStore } from "@/stores/base_info";

import { useForm, useField } from "vee-validate";
import * as yup from "yup";

import CvUpsellVariantInput from "@/components/organisms/pages/cart/CvUpsellVariantInput.vue";
import SpecifyDeliveryDate from "@/components/organisms/pages/cart/SpecifyDeliveryDate.vue";

import { CV_UPSELL_MODE, UPSELL_MODE } from "@/helpers/constants";

interface Props {
  orderNumber: string;
  baseUrl: string;
}

const props = defineProps<Props>();

const orderStore = useOrderStore();
const cartStore = useCartStore();
const validationStore = useValidationStore();
const baseInfoStore = useBaseInfoStore();

const products = computed(() => orderStore.productInfo.cvUpsellProducts);
const productId = computed(() => orderStore.cvUpSellVariantData.productSelectedId);

const variantId = computed(() => {
  const product = products.value.find((item) => item.id === productId.value);

  if (product) {
    const masterVariant = product.variants.find((item) => item.isMaster);
    if (masterVariant) {
      return masterVariant.id;
    }
  }

  return null;
});

const mode = computed(() => baseInfoStore.baseInfo?.shippingCarrierBasedOn ?? "");

const shippingCarrierOptions = computed(() => cartStore.shippingCarrierOptions);

const shippingCarrierVariables = computed(() => ({
  input: {
    paymentMethodCode: cartStore.cart.paymentData.paymentMethodCode,
    productData: [
      {
        id: orderStore.cvUpSellVariantData.productSelectedId,
        quantity: 1,
        variantData: [
          {
            variantId: orderStore.cvUpSellVariantData.variantId,
          },
        ],
      },
    ],
  },
}));

const { validate } = useForm({
  validationSchema: yup.object().shape({
    variantValues: yup.array().of(
      yup.object({
        value: yup.string().required(),
      }),
    ),
    subsOrderData: yup.object({
      paymentSchedule: yup.string().nullable(),
      scheduledToBeDeliveredEveryXMonth: yup
        .number()
        .nullable()
        .when(["paymentSchedule"], {
          is: (paymentSchedule: any) => ["date", "day_of_week"].includes(paymentSchedule),
          then: (schema) => schema.required(),
        }),
      scheduledToBeDeliveredOnXthDay: yup
        .number()
        .nullable()
        .when(["paymentSchedule"], {
          is: (paymentSchedule: any) => paymentSchedule == "date",
          then: (schema) => schema.required(),
        }),
      scheduledToBeDeliveredEveryXDay: yup
        .number()
        .nullable()
        .when(["paymentSchedule"], {
          is: (paymentSchedule: any) => paymentSchedule == "term",
          then: (schema) => schema.required(),
        }),
      scheduledToBeDeliveredEveryXDayOfWeek: yup
        .number()
        .nullable()
        .when(["paymentSchedule"], {
          is: (paymentSchedule: any) => paymentSchedule == "day_of_week",
          then: (schema) => schema.required(),
        }),
      scheduledToBeDeliveredOnXthDayOfWeek: yup
        .number()
        .nullable()
        .when(["paymentSchedule"], {
          is: (paymentSchedule: any) => paymentSchedule == "day_of_week",
          then: (schema) => schema.required(),
        }),
    }),
  }),
  initialValues: {
    variantValues: [{ value: orderStore.cvUpSellVariantData.variantId }],
    productsData: [
      {
        id: productId.value,
        isRecurring: orderStore.cvUpSellVariantData.isRecurring,
      },
    ],
    subsOrderData: {
      paymentSchedule: "",
      scheduledToBeDeliveredEveryXMonth: null,
      scheduledToBeDeliveredOnXthDay: null,
      scheduledToBeDeliveredEveryXDay: null,
      scheduledToBeDeliveredEveryXDayOfWeek: null,
      scheduledToBeDeliveredOnXthDayOfWeek: null,
    },
  },
});

const { value: variantValues }: any = useField("variantValues");
const { value: productsData }: any = useField("productsData");
const { value: subsOrderData }: any = useField("subsOrderData");
const { value: specifyDeliveryDate }: any = useField("specifyDeliveryDate");

const { value: shippingAddressData }: any = useField("shippingAddressData");
const { value: billingAddressData }: any = useField("billingAddressData");
const { value: shippingCarrierId }: any = useField("shippingCarrierId");

watch(
  () => variantValues.value.length,
  (newVal, oldVal) => {
    if (newVal > oldVal) {
      variantValues.value.pop();
      variantValues.value.push({ value: null });
    }
  },
);

watch(
  () => productId.value,
  () => {
    if (productsData.value?.[0]?.id || !productId.value) return;

    productsData.value = [
      {
        id: productId.value,
        isRecurring: orderStore.cvUpSellVariantData.isRecurring,
      },
    ];
  },
);

watch(shippingCarrierOptions, () => {
  shippingCarrierId.value = shippingCarrierOptions.value?.[0]?.id;
});

watch(
  () => orderStore.cvUpSellVariantData,
  () => {
    if (mode.value == "payment_method") return;
    cartStore.fetchShippingCarrierOptions(shippingCarrierVariables.value);
  },
  { deep: true },
);

const getData = (productId: number | null) => {
  const product = products.value.find((item) => item.id == productId);

  if (product) {
    return product.variantCombinations;
  }

  return null;
};

const validateModification = () => {
  const selectedVariantId = orderStore.cvUpSellVariantData.variantId || variantId.value;
  cartStore
    .validateOfferCvUpsell(
      props.orderNumber,
      selectedVariantId,
      subsOrderData.value,
      specifyDeliveryDate.value,
      shippingCarrierId.value,
    )
    .then(() => {
      cartStore.offerData[props.orderNumber] = selectedVariantId;
      cartStore.offerData.isRecurring = orderStore.cvUpSellVariantData.isRecurring;

      cartStore.offerData.subsOrderData = subsOrderData.value;
      cartStore.offerData.specifyDeliveryDate = specifyDeliveryDate.value;
      cartStore.offerData.shippingCarrierId = shippingCarrierId.value;

      window.location.href = "/lp/cv_confirm" + window.location.search + "&mode=" + UPSELL_MODE;
    });
};

const onSubmit = async function () {
  validationStore.submitAttempts++;

  if (orderStore.cvUpSellVariantData.variantId && !variantValues.value[0].value) {
    variantValues.value[0].value = orderStore.cvUpSellVariantData.variantId;
  }

  const result = await validate();

  if (result.valid) validateModification();
};

function handleChangeProduct(id: number) {
  const data = getData(id);
  const product = products.value.find((item) => item.id == id);

  const isRecurring = product?.isRecurring || false;
  orderStore.cvUpSellVariantData.isRecurring = isRecurring;

  if (data) {
    orderStore.cvUpSellVariantData.variantId = null;
  } else {
    if (product) {
      const variant = product.variants.find((item) => item.isMaster);

      if (variant && !product.variantCombinations) {
        orderStore.cvUpSellVariantData.variantId = variant.id;
      }
    }
  }

  variantValues.value = [{ value: orderStore.cvUpSellVariantData.variantId }];
  productsData.value = [{ id: id, isRecurring: isRecurring }];
  subsOrderData.value = {};
}

// ======================================== Add Event Click Jump Product =========================
const addEventClickJumpProduct = () => {
  const els = document.querySelectorAll("a[data-select-id]");

  els.forEach((el) => {
    el.addEventListener("click", () => {
      if (el.getAttribute("href") == "#lp-form") {
        const cvUpsellView = document.querySelector("#cv-upsell-form");
        cvUpsellView?.scrollIntoView();
      }

      const buyMultipleVariant = orderStore.productInfo.buyMultipleVariant;
      const generalVariantDisplay = orderStore.productInfo.generalVariantDisplay;
      const ownVariantDisplay = orderStore.productInfo.ownVariantDisplay;

      if (buyMultipleVariant || generalVariantDisplay || ownVariantDisplay) return;

      const variantMasterId = el.getAttribute("data-select-id");
      if (!variantMasterId) return;

      const product = products.value.find(
        (item) => item.variantMasterId.toString() === variantMasterId,
      );

      if (!product) return;

      orderStore.cvUpSellVariantData.productSelectedId = product.id;
      handleChangeProduct(product.id);
    });
  });
};
// ===============================================================================================

onBeforeMount(() => {
  addEventClickJumpProduct();

  shippingAddressData.value = {
    sameWithBillingAddress: cartStore.cart.shippingAddressData.sameWithBillingAddress,
    prefectureId: cartStore.cart.shippingAddressData.prefectureId,
  };

  billingAddressData.value = { prefectureId: cartStore.cart.billingAddressData.prefectureId };

  shippingCarrierId.value = cartStore.cart.shippingCarrierId;
});
</script>

<style scoped lang="scss">
.upsell-variant {
  max-width: 500px;
}
</style>
