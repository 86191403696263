const SOFT_BANK_PAYMENT_METHOD_CODE = "sbps_creditcard_one_time";
const PAYGENT_PAYMENT_METHOD_CODE = "paygent_creditcard_one_time";

const CARD_REQUIRED_PAYMENT_METHOD_CODES = [
  "payjp",
  "gmo_creditcard_one_time",
  SOFT_BANK_PAYMENT_METHOD_CODE,
  PAYGENT_PAYMENT_METHOD_CODE,
];

const AVAILABLE_IMAGE_CARD_BRAND = [
  "americanexpress",
  "applepay",
  "dinersclub",
  "discover",
  "jcb",
  "mastercard",
  "visa",
];

const NORMAL_MODE = "normal";
const XSELL_MODE = "xsell";
const CV_UPSELL_MODE = "cvUpsell";
const UPSELL_MODE = "upsell";

export {
  CARD_REQUIRED_PAYMENT_METHOD_CODES,
  SOFT_BANK_PAYMENT_METHOD_CODE,
  AVAILABLE_IMAGE_CARD_BRAND,
  PAYGENT_PAYMENT_METHOD_CODE,
  XSELL_MODE,
  UPSELL_MODE,
  CV_UPSELL_MODE,
  NORMAL_MODE,
};
